<template>
    <div class="container review" ref="container">
        <div
            class="profile-img ui-bg-img-cover"
            :style="{
                backgroundImage: `url(${$lib.cdnUrl(artistData.imgUrl1)})`,
            }"
        ></div>
        <div class="text item-1">{{ teamName }}님은 어떠셨나요?</div>
        <!-- <div class="text item-2">리뷰를 남겨주세요. 정말 많은 분들께 도움이 될거예요!!</div> -->

        <div class="star-ratings">
            <StarRating v-model="formData.pointCount" :gutter="16" :size="40" outline editable />
        </div>

        <div class="ui-border-line ui-h-1 ui-mt-4 ui-mb-4" />

        <div class="write-review--evaluate">
            <ul>
                <template v-for="(row, key) in goodItemList">
                    <li
                        class="evaluate--item"
                        :key="key"
                        @click="
                            () => {
                                const index = formData.itemGood.indexOf(row)
                                if (index === -1) {
                                    formData.itemGood.push(row)
                                } else {
                                    formData.itemGood.splice(index, 1)
                                }
                            }
                        "
                    >
                        <CheckIcon class="evaluate--item--icon" width="28px" height="28px" :value="formData.itemGood.includes(row)" />
                        {{ row }}
                    </li>
                </template>
            </ul>

            <p :style="{ marginTop: '35px' }">
                안좋은 점이 있었다면? <span class="color-lavender" @click="allowBadItem = true">추가 선택하기</span>
            </p>

            <template v-if="allowBadItem">
                <div class="ui-border-line" :style="{ marginTop: '35px', marginBottom: '35px', height: '1px' }" />

                <p :style="{ marginBottom: '35px' }">
                    <b>걱정하지 마세요.</b><br />
                    <b>평가는 누가 남겼는지 알 수 없고 익명이 보장돼요.</b>
                </p>

                <ul>
                    <template v-for="(row, key) in badItemList">
                        <li
                            class="evaluate--item"
                            :key="key"
                            @click="
                                () => {
                                    const index = formData.itemBad.indexOf(row)
                                    if (index === -1) {
                                        formData.itemBad.push(row)
                                    } else {
                                        formData.itemBad.splice(index, 1)
                                    }
                                }
                            "
                        >
                            <CheckIcon
                                class="evaluate--item--icon"
                                width="28px"
                                height="28px"
                                :value="formData.itemBad.includes(row)"
                            />
                            {{ row }}
                        </li>
                    </template>
                </ul>
            </template>
        </div>

        <div class="ui-border-line ui-h-1 ui-mt-4 ui-mb-4" />

        <template v-if="isGuest">
            <div
                :style="{
                    marginTop: '-40px',
                }"
            >
                <input type="text" placeholder="이름" maxlength="15" v-model="guestFormData.userName" />
                <div class="ui-border-line ui-h-1" />
            </div>
        </template>

        <div class="ui-border-line ui-h-0 ui-mt-4" />

        <div
            :style="{
                fontSize: '24px',
                color: '#000',
                margin: '0 8.3px 17.5px',
                fontWeight:500,
            }"
        >
            리뷰를 작성해주세요.
        </div>

        <div
            class="ui-textarea"
            :style="{
                marginBottom: '35px',
            }"
        >
            <textarea :value="formData.detail" @input="inputDetail" @focus="e => scrollDown(e)" />

            <div v-if="!formData.detail" class="ui-textarea-placeholder">
                진행 후기, 장단점 등을 솔직하게 알려주세요.<br />
                많은 분들께 큰 도움이 됩니다!
            </div>
        </div>

        <footer-box submit-text="저장" :submit-disabled="!validateOk" :submit-cb="() => saveReview()" />
    </div>
</template>

<script>
import StarRating from '@/components/common/StarRating.vue'
import FooterBox from '@/components/common/FooterBox.vue'
import CheckIcon from '@/components/common/CheckIcon.vue'

export default {
    name: 'WriteReview',
    components: {
        StarRating,
        FooterBox,
        CheckIcon,
    },
    data: () => ({
        artistData: {},
        reviewItemData: [],
        myReviewData: {},
        formData: {
            pointCount: 0,
            pointCount2: 0,
            pointCount3: 0,
            detail: '',
            itemGood: [],
            itemBad: [],
        },
        guestFormData: {
            userName: '',
        },
        allowBadItem: false,
    }),
    computed: {
        userData() {
            return this.$store.state.user.userData
        },
        isGuest() {
            return !('useridx' in this.userData) || this.userData.useridx == 0
        },
        teamName() {
            let teamName = this.artistData.teamName

            if (typeof teamName === 'string' && teamName.length > 7) teamName = teamName.replace(/(.{7}).+/, '$1...')

            return teamName
        },
        validateOk() {
            let validator = this.formData.pointCount && this.formData.pointCount2 && this.formData.pointCount3 && this.formData.detail
            if (this.isGuest) validator = validator && this.guestFormData.userName
            return validator
        },
        isModify() {
            return (this.myReviewData && Object.keys(this.myReviewData).length > 0) || false
        },
        goodItemList() {
            return [
                '응답이 빨라요.',
                '약속 시간을 잘 지켜요.',
                '친절하고 자세하게 설명해줘요.',
                '출발 전에 미리 연락을 해줘요.',
                '필요한 것들을 잘 챙겨줘요.',
                '영상,사진보다 실제가 더 뛰어나요.',
            ]
        },
        badItemList() {
            return [
                '연락이 잘 안돼요.',
                '시간 약속을 안 지켜요.',
                '설명이나 안내가 불친절해요.',
                '출발 또는 도착 연락이 없어요.',
                '영상이나 설명과 달라요.',
                '추가금을 요구해요.',
                '일방적으로 약속을 취소했어요.',
            ]
        },
    },
    watch: {
        'formData.pointCount'(point) {
            this.formData.pointCount2 = point
            this.formData.pointCount3 = point
        },
    },
    beforeMount() {
        this.artistData = JSON.parse(sessionStorage.getItem('artistData') || '{}')
        this.reviewItemData = JSON.parse(sessionStorage.getItem('reviewItemData') || '[]')
        this.myReviewData = JSON.parse(sessionStorage.getItem('myReviewData'))

        if (this.myReviewData) {
            this.formData.detail = this.myReviewData.detail
            this.formData.pointCount = Number(this.myReviewData.pointCount)
            this.formData.pointCount2 = Number(this.myReviewData.pointCount2)
            this.formData.pointCount3 = Number(this.myReviewData.pointCount3)
        }
    },
    methods: {
        inputDetail(e) {
            this.formData.detail = e.target.value;
        },
        saveReview(confirm = false) {
            const req = {
                method: 'post',
                url: `/artist/review/${this.$route.params.artistidx}`,
                data: this.formData,
            }

            if (this.isModify) {
                req.method = 'put'
                req.url = `/artist/review/${this.myReviewData.uid}`
            }

            if (this.isGuest) {
                if (!confirm) {
                    this.$store.commit('setModal', {
                        show: true,
                        title: `리뷰를 등록하시겠습니까?`,
                        body: `한번 등록한 리뷰는 수정 및 삭제할 수 없어요.`,
                        confirmCb: () => {
                            this.saveReview(true)
                        },
                    })
                    return
                }
                req.method = 'post'
                req.url = '/web/review'
                req.data = {
                    artistidx: this.$route.params.artistidx,
                    userName: this.guestFormData.userName,
                    reviewDetail: this.formData.detail,
                    pointCount: this.formData.pointCount,
                    pointCount2: this.formData.pointCount2,
                    pointCount3: this.formData.pointCount3,
                    itemGood: this.formData.itemGood,
                    itemBad: this.formData.itemBad,
                }
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)
                    return
                })
                .catch(error => {
                    log.error(error)
                    return
                    // this.$toast('서버 오류가 발생하였습니다')
                })
                .then(() => {
                    this.$router.back()
                })
        },
        scrollDown(e) {
            this.$nextTick().then(() => {
                const top = e.target.offsetTop || this.$refs.container.scrollHeight
                this.$refs.container.scrollTop = top
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.profile-img {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    margin: 2.5rem auto;
    background-position: center;
}

.text {
    text-align: center;

    &.item-1 {
        margin-bottom: 25px;
        font-size: 2.8rem;
        line-height: 2.8rem;
        color: #000;
        font-weight: 600;
    }

    &.item-2 {
        margin-bottom: 5rem;
        font-size: 1.8rem;
        color: #979797;
    }
}

.star-ratings {
    display: flex;
    justify-content: center;
    margin-bottom: 46.5px;
}

.write-review--evaluate {
    ul {
        li.evaluate--item {
            display: flex;
            align-items: center;
            font-size: 21px;
            line-height: 31px;
            color: #000;
            font-weight: 400;
            margin-bottom: 25px;

            .evaluate--item--icon {
                margin-right: 16.5px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    p {
        font-size: 1.9rem;
        color: #919191;
        font-weight: 400;
        line-height: 25px;

        b {
            font-weight: 600;
            color: #000;
        }
    }
}

input {
    height: 8rem;
    color: #ababab;
    font-size: 1.8rem;
    width: 100%;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
}

.ui-textarea {
    position: relative;

    textarea {
        position: relative;
        z-index: 1;
        padding: 16px;
        border: 1px solid #979797;
        border-radius: 5px;
        width: 100%;
        min-height: 140px;
        font-size: 18px !important;
        line-height: normal;
        background-color: transparent;
        box-sizing: border-box;
        display: block;
    }

    .ui-textarea-placeholder {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        padding: 16px;
        width: 100%;
        min-height: 140px;
        font-size: 18px;
        line-height: normal;
        color: #afafaf;
        white-space: pre-line;
        box-sizing: border-box;
    }
}
</style>
